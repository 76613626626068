import React,{useState} from 'react';
import axios from 'axios';
import { useStyles } from '../theme/theme';
import {Grid, TextField} from '@material-ui/core'
import {navigate} from 'gatsby';
import {
    withStyles
  } from '@material-ui/core/styles';
import Thankyou from './Thankyou';

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3D55A4',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3D55A4',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#E0E0E0',
        },
        '&:hover fieldset': {
          borderColor: '#E0E0E0',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3D55A4',
          
        },
      },
    },
  })(TextField);

  export default function ContactUs({isSmall}){
    const classes = useStyles()

    const [open1, setOpen1] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [lastName, setLastName] = useState('')
    const [message, setMessage] = useState('')


    const handleSibmit = (e) => {
      
        var bodyFormData = new FormData()
        bodyFormData.append('your-first-name', firstName);
        bodyFormData.append('your-last-name', lastName);
        bodyFormData.append('your-email', email);
        bodyFormData.append('your-message', message);
        bodyFormData.append('your-subject', 'Buyer from Ocean Fishery Website');
        e.preventDefault()
        axios({
            method: "post",
            url: "https://of.designer-dev.com/wp-json/contact-form-7/v1/contact-forms/164/feedback",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
              //handle success
              console.log(response);
              handleClickOpen1();
              setTimeout(() => {
                handleClose1()
                navigate('/')
              }, 10000);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
    
    }
    const handleClickOpen1 = () => {
      setOpen1(true);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
    };
    return(
        <>
        <form onSubmit={handleSibmit} id="contact-us">
        <h3 >Get in Touch</h3>
         <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <CssTextField
              id="outlined-basic"
              label="First Name" 
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
                type="text"
                name="first-name"
                className={classes.first_name}
                
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CssTextField
              type="text"
                id="outlined-basic"
              label="Last Name" 
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
                name="last-name"
                className={classes.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
                id="outlined-basic"
                label="Email Address" 
                variant="outlined"
                value={email}
              onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                className={classes.email_address}
              />
            </Grid>
            <Grid item xs={12}>
              <CssTextField
              id="outlined-basic"
              label="Message" 
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
                rows={10}
                type="text"
                className={classes.message_box}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: `${isSmall ? "flex" : "flex"}` }}
            >
              <button className={classes.btn_variant_2} type="submit">Send Message</button>
            </Grid>
          </Grid>
          </form>
          <Thankyou handleClose1={handleClose1} open1={open1}/>
        </>
    )
  }