import React from 'react';
import {Dialog, useMediaQuery} from '@material-ui/core';
import { useStyles } from '../theme/theme';
import Lottie from 'react-lottie';
import Congratulation from '../lottie/congratulations.json'
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';

export default function Thankyou({open1,handleClose1}){
    const classes = useStyles()
        const theme = useTheme()
      const isMob = useMediaQuery(theme.breakpoints.down(500));
    return(
        <>
        <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="responsive-dialog-title"
        PaperProps ={{
            classes: {
             root: classes.thankyou
            }
          }}
          BackdropProps={{
            classes: {
             root: classes.backdrop
            }
           }
          }
        >
            <div >
            <CloseIcon onClick={handleClose1} style={{cursor:"pointer",fontSize:"28px",position: "absolute",
    right: `${isMob ? "20px" : "50px"}`,
    top: `${isMob ? "20px" : "30px"}` }}  />
            {/* <Lottie options={defaultOptions} height={300} />
                <h3>
                Thank you for reaching out.
                </h3>
                <p>
                One of our sales representative will reach out to you soon
                </p> */}
            </div>
        </Dialog>
        </>
    )
}
